import React, { useContext, useState } from "react";

import { ModalViewer } from "../../../../components/ModalViewer/ModalViewer";
import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Player.module.css";

export const Player = () => {
  const [stack, setStack] = useState(true);

  const { video, expertBackgroundTriangle } = useContext(ZACPageContext);

  return (
    <section className={styles.promiseContent}>
      <div className={styles.video} style={{ zIndex: stack ? 10 : 20 }}>
        <ModalViewer
          image={video.thumbnail.gatsbyImageData}
          video={video.youtubeUrl}
          setStack={setStack}
        />
      </div>
      <div
        className={styles.overlay}
        style={{
          backgroundImage: `url(${expertBackgroundTriangle.file.url})`,
        }}
      />
    </section>
  );
};
