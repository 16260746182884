import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./Educator.module.css";

export const Educator = ({ image, job, name, techName }) => {
  return (
    <div className={styles.root}>
      <GatsbyImage
        className={styles.image}
        width={300}
        height={255}
        image={image.gatsbyImageData}
        alt={image.file.name}
      />
      <div className={styles.info}>
        <div>{techName}</div>
        <div>{name}</div>
        <div>{job}</div>
      </div>
    </div>
  );
};
