import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const { heroBackground, heroTitle, qualiopi } = useContext(ZACPageContext);

  return (
    <section
      className={styles.root}
      style={{
        backgroundImage: `url(${heroBackground.file.url})`,
      }}
    >
      <div className={styles.overlay} />
      <div className={styles.overlayTriangle} />
      <SlideTransition thresholds={100} delay={0.4}>
        <h1 className={styles.text}>{renderRichText(heroTitle)}</h1>
      </SlideTransition>
      <div className={styles.logo}>
        <GatsbyImage image={qualiopi.gatsbyImageData} alt="Qualiopi logo" />
      </div>
    </section>
  );
};
