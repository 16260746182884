import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { ZacTitle } from "../../../../components/ZacTitle/ZacTitle";
import { ZACPageContext } from "../../../../helpers/pagesContext";
import { Educator } from "./components/Educator";
import * as styles from "./Experts.module.css";

export const Experts = () => {
  const { expertTitle, expertList } = useContext(ZACPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <ZacTitle>
          <h2>{renderRichText(expertTitle)}</h2>
        </ZacTitle>
      </div>
      <div className={styles.list}>
        {expertList.map(({ image, id, job, name, techName }) => (
          <div key={id}>
            <Educator image={image} job={job} name={name} techName={techName} />
          </div>
        ))}
      </div>
    </section>
  );
};
