import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { ZacTitle } from "../../../../components/ZacTitle/ZacTitle";
import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Reason.module.css";

export const Reason = () => {
  const { reasonList, reasonTitle } = useContext(ZACPageContext);

  return (
    <section className={styles.root}>
      <ZacTitle>
        <h2>{renderRichText(reasonTitle)}</h2>
      </ZacTitle>
      <div className={styles.reasonList}>
        {reasonList.map(({ id, name, image, description }, i) => {
          return (
            <SlideTransition key={id} thresholds={200} delay={0.2 + 0.1 * i}>
              <div className={styles.reason}>
                <div>
                  <GatsbyImage
                    className={styles.image}
                    height={100}
                    image={image.gatsbyImageData}
                    alt={image.file.name}
                  />
                </div>
                <div>
                  <h3 className={styles.name}>{name}</h3>
                  <div className={styles.description}>
                    {renderRichText(description)}
                  </div>
                </div>
              </div>
            </SlideTransition>
          );
        })}
      </div>
    </section>
  );
};
