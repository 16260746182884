import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { ZACPageContext } from "../helpers/pagesContext";
import { FORMATION } from "../helpers/route";
import AcademyScene from "../scenes/Academy/Academy";

export default function Formation(props) {
  return (
    <Layout currentRoute={FORMATION} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Zenity Academy"
        description="La Zenity Academy, certifiée Qaliopi, propose des formations telles que les API, Cucumber, Gherkin, Jira, Xray, Jenkins, Agiles, Selenium, bdd, DevOps, tests exploratoires, tests logiciels..."
      />
      <ZACPageContext.Provider value={props.data.contentfulZac}>
        <AcademyScene />
      </ZACPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulZac(node_locale: { eq: $locale }) {
      node_locale
      qualiopi {
        gatsbyImageData
        file {
          url
        }
      }
      videoBackgroundTriangle {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      expertBackgroundTriangle {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      heroBackground {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      heroTitle {
        raw
      }
      heroTriangle {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      speachTitle
      speachText {
        raw
      }
      speachJob
      speachImage {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      speachAuthors
      reasonTitle {
        raw
      }
      reasonList {
        id
        image {
          gatsbyImageData
          file {
            fileName
            url
          }
        }
        name
        title
        description {
          raw
        }
      }
      expertTitle {
        raw
      }
      expertList {
        image {
          file {
            fileName
            url
          }
          gatsbyImageData
        }
        id
        job
        name
        title
        techName
      }
      expertTriangle {
        file {
          fileName
          url
        }
      }
      mainTestimonial {
        formationName
        id
        image {
          file {
            fileName
            url
          }
          gatsbyImageData
        }
        job
        name
        testimony {
          raw
        }
        title
      }
      testimonialTriangle {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      testimonialBackgroundTriangle {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      video {
        youtubeUrl
        thumbnail {
          file {
            fileName
            url
          }
          gatsbyImageData
        }
      }
      footerTitle
      footerCta
      footerBackground {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      testimonialsList {
        formationName
        job
        name
        month
        testimony {
          raw
        }
        title
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
