import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Speach.module.css";

export const Speach = () => {
  const { speachTitle, speachText, speachJob, speachAuthors, speachImage } =
    useContext(ZACPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.overlayTriangle} />
      <div className={styles.container}>
        <div className={styles.speachContainer}>
          <h2 className={styles.speachTitle}>{speachTitle}</h2>
          <div className={styles.speachTexte}>{renderRichText(speachText)}</div>
          <div className={styles.speachFooter}>
            <div className={styles.speachFooterImage}>
              <GatsbyImage
                image={speachImage.gatsbyImageData}
                alt={speachImage.file.name}
              />
            </div>
            <div className={styles.speachFooterInfo}>
              <div>{speachAuthors}</div>
              <div>{speachJob}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
