import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { ZACPageContext } from "../../helpers/pagesContext";
import { CAREER } from "../../helpers/route";
import * as styles from "./Academy.module.css";
import { Experts } from "./components/Experts/Experts";
import { Hero } from "./components/Hero/Hero";
import { Player } from "./components/Player/Player";
import { Reason } from "./components/Reason/Reason";
import { Speach } from "./components/Speach/Speach";
import Testimonials from "./components/Testimonials/Testimonials";
import { Trainer } from "./components/Trainer/Trainer";

export default function Formation() {
  const {
    footerBackground,
    node_locale,
    footerTitle,
    footerCta,
    expertTriangle,
  } = useContext(ZACPageContext);

  return (
    <div>
      <div className={styles.hero}>
        <Hero />
        <Speach />
      </div>
      <Reason />
      <div className={styles.center}>
        <div
          className={styles.overlaycenter}
          style={{
            backgroundImage: `url(${expertTriangle.file.url})`,
          }}
        />
        <Experts />
        <Trainer />
      </div>
      <Testimonials />
      <Player />
      <OverFooter
        title={footerTitle}
        buttonLink={CAREER}
        buttonLabel={footerCta}
        image={footerBackground.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </div>
  );
}
