import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Trainer.module.css";

export const Trainer = () => {
  const {
    mainTestimonial: { image, testimony, name, job },
  } = useContext(ZACPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            className={styles.image}
            image={image.gatsbyImageData}
            alt={image.file.name}
          />
          <StaticImage
            className={styles.vector}
            src="../../../../resources/images/inside-zenity/partner/vector.png"
            alt="vector"
          />
        </div>
        <div className={styles.infoText}>
          <div>{renderRichText(testimony)}</div>
          <div>{name}</div>
          <div>{job}</div>
        </div>
      </div>
    </section>
  );
};
