// extracted by mini-css-extract-plugin
export var arrows = "Testimonials-module--arrows--a2dba";
export var container = "Testimonials-module--container--d71ae";
export var formation = "Testimonials-module--formation--36343";
export var image = "Testimonials-module--image--466ef";
export var month = "Testimonials-module--month--f45a0";
export var overlay = "Testimonials-module--overlay--a32aa";
export var people = "Testimonials-module--people--2f921";
export var testimonal = "Testimonials-module--testimonal--b7701";
export var testimonyContainer = "Testimonials-module--testimonyContainer--f99f2";
export var testimonyText = "Testimonials-module--testimonyText--36b1d";