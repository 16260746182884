import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { Arrows } from "../../../../components/Arrows/Arrows";
import { ZACPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Testimonials.module.css";

export default function Testimonials() {
  const [value, setValue] = useState(0);
  const {
    testimonialsList,
    testimonialBackgroundTriangle,
    videoBackgroundTriangle,
  } = useContext(ZACPageContext);

  const handleChangeIndex = (index) => {
    setTimeout(() => {
      setValue(index);
    }, 250);
  };

  const imageData = getImage(testimonialBackgroundTriangle.gatsbyImageData);

  return (
    <div className={styles.container}>
      <div
        className={styles.overlay}
        style={{
          backgroundImage: `url(${videoBackgroundTriangle.file.url})`,
        }}
      />
      <GatsbyImage
        className={styles.image}
        image={imageData}
        alt="illustration"
      />
      <div className={styles.testimonyContainer}>
        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
          springConfig={{
            duration: "0.5s",
            easeFunction: "ease-out",
            delay: "0s",
          }}
        >
          {testimonialsList.map(
            ({ name, job, formationName, testimony, month }) => {
              return (
                <div key={name} className={styles.testimonal}>
                  <div className={styles.testimonyText}>
                    {renderRichText(testimony)}
                  </div>
                  <div className={styles.people}>
                    {name} {job}
                  </div>
                  <div className={styles.formation}>{formationName}</div>
                  <div className={styles.month}>{month}</div>
                </div>
              );
            }
          )}
        </SwipeableViews>
        <div className={styles.arrows}>
          <Arrows
            value={value}
            setValue={setValue}
            max={testimonialsList.length}
          />
        </div>
      </div>
    </div>
  );
}
